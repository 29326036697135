<template>
	<main class="page-container">
		<div class="page-header-full">
			<div class="container">
				<h2 class="page-header-full__title">소상공인 뉴스</h2>
				<p class="page-header-full__desc">온라인진출 관련<br />다양한 정보를 확인하세요</p>
			</div>
		</div>
		<div class="page-body">
			<!-- Tab list -->
			<nav class="page-nav style-orange">
				<div class="container">
					<ul class="nav-list">
						<li class="nav-item">
							<router-link to="/sentcont/home">
								<span class="nav-text">소상공인 뉴스 홈</span>
							</router-link>
						</li>
						<li v-for="(item, idx) in ctgrItems" class="nav-item" :key="idx" :class="{ 'is-active': item.dcd === sentContCtgrDcd }">
							<router-link :to="`/sentcont/${item.dcd}/list`" class="nav-link">
								<span class="nav-text">{{ item.dcdVal }}</span>
							</router-link>
						</li>
					</ul>
				</div>
			</nav>
			<!-- //Tab list -->

			<div class="container">
				<div class="card-list-wrapper">
					<div class="list-header">
						<template>
							<div class="header-column"></div>
							<div class="header-column">
								<ul class="sort-list">
									<li class="sort-item" :class="{ 'is-active': sortCd === '' }"><button type="button" @click.prevent="selectSort('')">최신순</button></li>
									<li class="sort-item" :class="{ 'is-active': sortCd === 'I' }"><button type="button" @click.prevent="selectSort('I')">인기순</button></li>
								</ul>
							</div>
						</template>
					</div>
					<common-list :div-class="['list-body']" :is-loading="isLoading" :is-no-result="isNoResult">
						<card-list :items="items" :showTitle="true" />
					</common-list>
				</div>
				<!-- <portal-pagination page-name="SentContList" :pageListSize="pageListSize" :total-count="listTotal" :page-size="pageSize" :page-no="pageNo" :query="queries"></portal-pagination> -->
				<portal-pagination v-if="isPaging" page-name="SentContList" :page-no="pageNo" :page-size="pageSize" :page-list-size="pageListSize" :total-count="totalCount" :query="queries" :parent-method-name="pagingMethodName" @getSentContList="getSentContList"></portal-pagination>
			</div>
		</div>
	</main>
</template>

<script>
import { ACT_GET_SENTCONT_LIST, ACT_GET_COMMON_CODE_LIST } from '@/store/_act_consts';
import { MUT_SET_SENT_CONT_CTGR_ITEMS } from '@/store/_mut_consts';
import { getItems, getCheckItems, lengthCheck, setPaging, tryResCallback } from '@/assets/js/utils';
import CardList from '@/components/sentcont/CardList';
import NoResult from '@/components/common/NoResult';
import CommonList from '@/components/common/CommonList';
import PortalPagination from '@/components/PortalPaginationRoute';
import { mapGetters } from 'vuex';
import menus from '@/assets/js/menus';

export default {
	name: 'SentContList',
	components: {
		NoResult,
		CommonList,
		CardList,
		PortalPagination,
	},
	watch: {
		$route(to) {
			this.sortCd = '';
			this.sentContCtgrDcd = to.params.sentContCtgrDcd;
		},
		sentContCtgrDcd() {
			this.getSentContList(1);
		},
	},
	data: () => ({
		pagingMethodName: 'getSentContList',
		hasMore: false,
		totalCount: 0,
		pageListSize: 10,
		pageSize: 12,
		pageNo: 1,
		listTotal: 20,
		sortCd: '',
		isNoResult: false,
		isPaging: false,
		isLoading: false,
		sentContCtgrDcd: 1,
		//ctgrItems: [],
		items: [],
		tryCount: 0,
		//menus: menus.filter((x) => x.code === 'information'), //모바일 중메뉴 데이터 (20220714 - hib)
	}),
	computed: {
		...mapGetters('common', ['isMobile']),
		...mapGetters('sentcont', ['ctgrItems']),
		queries() {
			const result = {};
			return result;
		},
	},
	created() {
		this.sentContCtgrDcd = this.$route.params.sentContCtgrDcd;
		this.getSentContCtgrList();
	},
	methods: {
		morePage() {
			if (this.hasMore) this.pageNo++;
			this.getSentContList();
		},
		selectSort(el) {
			if (el === 'I') {
				this.sortCd = 'I';
			} else {
				this.sortCd = '';
			}
			this.getSentContList(1);
		},
		/* 중메뉴이동 (20220714 - hib) */
		click() {
			let element = document.getElementById('select_list');
			element.classList.toggle('show_list');
			let element02 = document.getElementById('select_btn');
			element02.classList.toggle('active');
		},
		getSentContCtgrList() {
			//소상공인컨텐츠 카테고리 리스트 불러오기
			this.$store
				.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, {
					dcd: '1202',
					masterYn: 'N',
				})
				.then((res) => {
					const items = getCheckItems(res);
					this.$store.commit(`sentcont/${MUT_SET_SENT_CONT_CTGR_ITEMS}`, items);
				});
		},
		getSentContList(no) {
			// 리스트 데이터 클리어
			this.items = [];
			this.isNoResult = false;
			this.isPaging = false;

			//소상공인컨텐츠 리스트 불러오기
			this.$store
				.dispatch(`sentcont/${ACT_GET_SENTCONT_LIST}`, {
					sentContCtgrDcd: this.sentContCtgrDcd,
					sortCd: this.sortCd,
					expsrYn: 'Y',
					pageNo: no,
					pageSize: this.pageSize,
				})
				.then((res) => {
					if (lengthCheck(res)) {
						this.items = getCheckItems(res);
						this.isPaging = true;
						setPaging(this, res);
					} else {
						this.isNoResult = true;
						this.isPaging = false;
					}
				});
		},
	},
};
</script>
