<template>
	<!-- begin: card-list -->
	<ul class="card-list">
		<li class="card-item" v-for="(item, idx) in items" :key="idx">
			<router-link :to="`/sentcont/${item.sentContCtgrDcd}/view/${item.sentContId}`" class="card-item__link">
				<div class="card-item__image">
					<img :src="item.thmbnlImgPtUrl" :alt="item.sentContTit" />
				</div>
				<div class="card-item__title" v-if="showTitle">
					{{ item.sentContTit }}
				</div>
			</router-link>
		</li>
	</ul>
	<!-- end: card-list -->
</template>

<script>
export default {
	name: 'CardList',
	props: {
		items: Array,
		viewLink: String,
		showTitle: Boolean,
	},
};
</script>
